import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  colors,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Check as CheckIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  successContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    paddingBottom: '100px',
  },
  avatar: {
    marginTop: 20,
    height: 100,
    width: 100,
    backgroundColor: colors.green[600]
  },
  checkIcon: {
    height: 60,
    width: 60
  }
}));

export default function EpSuccess({ orderNo }) {
  const classes = useStyles();
  return (
    <Box
      maxWidth={450}
      mx="auto"
      className={classes.successContainer}
    >
      <Box
        display="flex"
        justifyContent="center"
      >
        <Avatar className={classes.avatar}>
          <CheckIcon className={classes.checkIcon} />
        </Avatar>
      </Box>
      <Box mt={2}>
        <Typography
          variant="h1"
          color="textPrimary"
          align="center"
        >
          EP-ASSURE Order is created successfully!
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          align="center"
        >
          EP-ASSURE Order No: {orderNo}
        </Typography>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          align="center"
        >
          An email will be sent to you shortly to provide details regarding your Order.
        </Typography>
      </Box>
    </Box>
  );
};