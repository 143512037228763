import React, {
  createContext,
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import { THEMES } from 'src/constants';
import { storeSettings } from 'src/utils/settings';

import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;
const SettingsContext = createContext();

const defaultSettings = {
  accountSettings: null
};

export function SettingsProvider({ settings, children }) {
  const [currentSettings, setCurrentSettings] = useState(settings || defaultSettings);
  const [accountSettings, setAccountSettings] = useState(null);

  const handleSaveSettings = (updatedSettings = {}) => {
    const mergedSettings = _.merge({}, currentSettings, updatedSettings);
    setCurrentSettings(mergedSettings);
    // storeSettings(mergedSettings);
  };

  const getAccountSettings = async () => {
    // if (localStorage.token) {
    //   setAuthToken(localStorage.token);
    // }

    try {
      const res = await axios.get(API_URL + '/api/auth/getAccountSettings');
      setAccountSettings(res.data)
      storeSettings(res.data)
      // dispatch({ type: SETTINGS_SUCCESS, payload: res.data });
    } catch (err) {
      // dispatch({ type: SETTINGS_FAIL });
    }
  };

  // setAccountSettings
  const handleAccountSettings = async formData => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      const res = await axios.post(API_URL + '/api/auth/setAccountSettings', formData, config);
      setAccountSettings(formData)
      storeSettings(formData)
      // dispatch({
      //   type: SETTINGS_SUCCESS,
      //   payload: res.data
      // });

    } catch (err) {
      // dispatch({
      //   type: SETTINGS_FAIL,
      //   payload: err.response.data.msg
      // });
    }
  };
  // backupAccountSettings
  const backupAccountSettings = async formData => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      const res = await axios.post(API_URL + '/api/auth/backupAccountSettings', formData, config);
      setAccountSettings(formData)
      storeSettings(formData)

    } catch (err) {

    }
  };
  // restoreAccountSettings
  const restoreAccountSettings = async (data) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      // const res = await axios.post(API_URL + '/api/auth/restoreAccountSettings', {}, config);
      // setAccountSettings(res.data)
      // storeSettings(res.data)
      
      axios({
        url: API_URL + '/api/auth/restoreAccountSettings', //your url
        method: 'POST',
        data: data, 
      }).then((res) => {
        // setIsSearching(false)
        setAccountSettings(res.data)
        storeSettings(res.data)
        // setSeverity('success')
        // setMessage('Restore was successfull.')
        // handleSClick()
        // return;
        return 'success';
      });

    } catch (err) {
      console.log(err);
      return 'error';
    }

  };

  useEffect(() => {
    document.dir = currentSettings.direction;
  }, [currentSettings]);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        accountSettings: accountSettings,
        saveSettings: handleSaveSettings,
        getAccountSettings,
        handleAccountSettings,
        backupAccountSettings,
        restoreAccountSettings
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.object
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
