import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { HelpCircle, X } from 'react-feather';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f2f2f2',
    paddingTop: 20,
    paddingBottom: 20
  },
  resultContainer: {
    width: '100%',
    height: '300px',
    border: 'solid 1px #9a9a9a',
    borderRadius: '10px',
    background: 'white',
    position: 'absolute',
    top: '42px',
    display: 'none',
    flexDirection: 'column',
  },
  resultHeader: {
    height: '50px',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center'
  },
  resultBody: {
    flexGrow: 1,
  },
  resultFooter: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
  },
  loadContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  resultList: {
    height: '200px',
    overflow: 'auto'
  }
}));

const dummyData = [
  {
    EntityName: 'test ENtityName1',
    Identification: 'test Identification1',
  },
  {
    EntityName: 'test ENtityName2',
    Identification: 'test Identification2',
  },
];

function Handshake({ className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef();
  const [isSearch, setIsSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [handShakeResult, setHandShakeResult] = useState([]);

  const handleSearchClick = () => {
    if (ref.current) {
      if (isSearch) {
        return;
      }
      if (searchValue) {
        ref.current.style.display = 'flex';
        setIsSearch(true);
        handleSearch();
      }
    }
  };
  const handleCloseContainer = () => {
    if (ref.current && !isSearch) {
      ref.current.style.display = 'none';
    }
  };
  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target) && e.target.className !== 'handshakeContainer' && !isSearch) {
      ref.current.style.display = 'none';
    }
  };
  const handleSearch = () => {
    if (searchValue) {
      // setIsSearch(false);
      // setHandShakeResult(dummyData);
      // return;
      axios
        .post(`${API_URL}/api/handshake/customsearch`, { searchValue, userId: '', userName: '' })
        .then((response) => {
          setIsSearch(false);
          setHandShakeResult(response.data);
        });
    }
  };

  const handleClickEntityName = (item) => {
    localStorage.setItem('entityName', item?.EntityName);
    localStorage.setItem('identification', item?.Identification);
    // history.push(`/login/${item?.EntityName}/${item?.Identification}`);
    history.push(`/login`);
  };

  const handleEnterKeyClick = (e) => {
    if (e.key === 'Enter') {
      console.log(e.key);
      handleSearchClick();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    }
  });


  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="md" style={{position: 'relative', padding: 0}}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <Box flexGrow={1} mr={2}>
            <TextField
              id="searchValue"
              label="Enter Company / Individual Name"
              variant="outlined"
              size="small"
              fullWidth
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyUp={handleEnterKeyClick}
              style={{backgroundColor: 'white'}}
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSearchClick}
            >
              Search Now
            </Button>
          </Box>
        </Box>
      <div className={clsx(classes.resultContainer, 'handshakeContainer')} ref={ref}>
        <div className={classes.resultHeader}>
          <IconButton  variant="contained" color="secondary" disabled={isSearch} onClick={handleCloseContainer}>
            <X size={26}/>
          </IconButton>
        </div>
        <hr/>
        <div className={classes.resultBody}>
          {isSearch ? (
            <div className={classes.loadContainer}>
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <div className={classes.resultList}>
              <Grid container style={{padding: '10px'}}>
                <Grid item xs={12} md={6}>
                  <Typography style={{ color: 'gray' }}>Individual or Business Name</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography style={{ color: 'gray' }}>Reference ID</Typography>
                </Grid>
              </Grid>
              <hr />
              {handShakeResult.map((item, index) => (
                <Grid
                  container
                  style={{padding: '10px', borderBottom: 'solid 1px #ebebeb', cursor: 'pointer'}}
                  onClick={() => handleClickEntityName(item)}
                  key={index}
                >
                  <Grid item xs={12} md={6}>
                    <Typography style={{  }}>{item?.EntityName}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography style={{  }}>{item?.Identification}</Typography>
                  </Grid>
                </Grid>
              ))}
            </div>
          )}
        </div>
        <hr/>
        <div className={classes.resultFooter}>
          <IconButton  variant="contained" color="secondary">
            <HelpCircle/>
          </IconButton>
          <Typography style={{paddingLeft: '10px'}}>
            Can't find the business you're looking for? 
            <Link
              component={RouterLink}
              to="/contactus"
              variant="body1"
              color="secondary"
              style={{paddingLeft: '5px', paddingRight: '5px'}}
            >
              Contact us
            </Link>
            for further investigation.
          </Typography>
        </div>
      </div>
      </Container>
    </div>
  );
}

Handshake.propTypes = {
  className: PropTypes.string
};

export default Handshake;
