/* eslint-disable react/no-array-index-key */
import React, {
    lazy,
    Suspense,
    Fragment
} from 'react';
import {
    Switch,
    Redirect,
    Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import PreOrderLayout from 'src/layouts/PreOrderLayout';
import CandidateLayout from 'src/layouts/CandidateLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/pages/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import PreOrderGuard from 'src/components/PreOrderGuard';
import CandidateGuard from 'src/components/CandidateGuard';

const routesConfig = [
    {
        exact: true,
        path: '/',
        component: () => < Redirect to="/home" />
    },
    {
        exact: true,
        guard: GuestGuard,
        layout: MainLayout,
        path: '/customer-purchase',
        component: lazy(() =>
            import('src/views/pages/Customer/Purchase'))
    },
    {
        exact: true,
        path: '/404',
        component: lazy(() =>
            import('src/views/pages/Error404View'))
    },
    {
        exact: true,
        guard: GuestGuard,
        layout: MainLayout,
        path: '/contactus',
        component: lazy(() =>
            import('src/views/pages/HomeView/ContactUs'))
    },
    {
        exact: true,
        guard: GuestGuard,
        layout: MainLayout,
        path: '/login',
        // path: '/login/:entityName?/:identification?',
        component: lazy(() =>
            import('src/views/auth/LoginView'))
    },
    {
        exact: true,
        layout: MainLayout,
        path: '/login-unprotected',
        component: lazy(() =>
            import('src/views/auth/LoginView'))
    },
    {
        exact: true,
        guard: GuestGuard,
        layout: MainLayout,
        path: '/register',
        component: lazy(() =>
            import('src/views/auth/RegisterView'))
    },
    {
        exact: true,
        path: '/register-unprotected',
        layout: MainLayout,
        component: lazy(() =>
            import('src/views/auth/RegisterView'))
    },
    {
        exact: true,
        guard: GuestGuard,
        layout: MainLayout,
        path: '/forgot',
        component: lazy(() =>
            import('src/views/auth/ForgotPassword'))
    },
    {
        exact: true,
        guard: GuestGuard,
        layout: MainLayout,
        path: '/reset/:token',
        component: lazy(() =>
            import('src/views/auth/ResetPassword'))
    },
    //customer pre employment process
    {
        exact: true,
        guard: PreOrderGuard,
        layout: MainLayout,
        path: '/customer/preorder/:preOrderId/login',
        component: lazy(() => import('src/views/pages/CustomerPreEmployment/'))
    },
    {
        exact: true,
        guard: PreOrderGuard,
        layout: MainLayout,
        path: '/customer/preorder/:preOrderId',
        component: lazy(() => import('src/views/pages/CustomerPreEmployment/'))
    },
    {
        exact: true,
        guard: PreOrderGuard,
        layout: PreOrderLayout,
        path: '/customer/preorder/:preOrderId/candidates',
        component: lazy(() => import('src/views/pages/CustomerPreEmployment/CandidateList/'))
    },
    {
        exact: true,
        guard: PreOrderGuard,
        layout: PreOrderLayout,
        path: '/customer/preorder/:preOrderId/candidates/create',
        component: lazy(() => import('src/views/pages/CustomerPreEmployment/CreateCandidate/'))
    },
    {
        exact: true,
        guard: PreOrderGuard,
        layout: PreOrderLayout,
        path: '/customer/preorder/:preOrderId/candidates/:candidateId/edit',
        component: lazy(() => import('src/views/pages/CustomerPreEmployment/EditCandidate/'))
    },
    //Candidate Creation process by form
    {
        exact: true,
        guard: CandidateGuard,
        layout: MainLayout,
        path: '/candidateform/:candInfoId/login',
        component: lazy(() => import('src/views/pages/CandidateForm/'))
    },
    {
        exact: true,
        guard: CandidateGuard,
        layout: MainLayout,
        path: '/candidateform/:candInfoId',
        component: lazy(() => import('src/views/pages/CandidateForm/'))
    },
    {
        exact: true,
        guard: CandidateGuard,
        layout: CandidateLayout,
        path: '/candidateform/:candInfoId/edit',
        component: lazy(() => import('src/views/pages/CandidateForm/Edit'))
    },
//
    {
        path: '/app',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                path: '/app',
                component: () => < Redirect to="/app/reports/dashboard" />
            }, 
            {
                exact: true,
                path: '/app/account',
                component: lazy(() =>
                    import('src/views/settings/AccountView'))
            },
            {
                exact: true,
                path: '/app/searchparam',
                component: lazy(() =>
                    import('src/views/settings/SearchParamView'))
            },
            {
                exact: true,
                path: '/app/profile',
                component: lazy(() =>
                    import('src/views/settings/UserView'))
            },
            {
                exact: true,
                path: '/app/reports/dashboard/:from?',
                component: lazy(() =>
                    import('src/views/reports/DashboardView'))
            }, 
            {
                exact: true,
                path: '/app/reports',
                component: () => < Redirect to="/app/reports/dashboard" />
            },
            {
                exact: true,
                path: '/app/management/companies',
                component: lazy(() =>
                    import('src/views/management/CompanyListView'))
            }, 
            {
                exact: true,
                path: '/app/management/companies/:companyId/edit',
                component: lazy(() =>
                    import('src/views/management/CompanyEditView'))
            },
            {
                exact: true,
                path: '/app/management/companies/new/create',
                component: lazy(() =>
                    import('src/views/management/CompanyCreateView'))
            },
            {
                exact: true,
                path: '/app/management/customers',
                component: lazy(() =>
                    import('src/views/management/CustomerListView'))
            },
            {
                exact: true,
                path: '/app/management/customers/create',
                component: lazy(() =>
                    import('src/views/management/CustomerCreateView'))
            }, 
            {
                exact: true,
                path: '/app/management/customers/:customerId/edit',
                component: lazy(() =>
                    import('src/views/management/CustomerEditView'))
            },
            {
                exact: true,
                path: '/app/management/categories',
                component: lazy(() =>
                    import('src/views/management/CategoryListView'))
            },
            {
                exact: true,
                path: '/app/management/categories/create',
                component: lazy(() =>
                    import('src/views/management/CategoryCreateView'))
            },
            {
                exact: true,
                path: '/app/management/categories/:categoryId/edit',
                component: lazy(() =>
                    import('src/views/management/CategoryEditView'))
            },
            {
                exact: true,
                path: '/app/management/products',
                component: lazy(() =>
                    import('src/views/management/ProductListView'))
            },
            {
                exact: true,
                path: '/app/management/products/create',
                component: lazy(() =>
                    import('src/views/management/ProductCreateView'))
            },
            {
                exact: true,
                path: '/app/management/products/:productId/edit',
                component: lazy(() =>
                    import('src/views/management/ProductEditView'))
            },
            {
                exact: true,
                path: '/app/management/pricing',
                component: lazy(() =>
                    import('src/views/management/FeeListView'))
            },
            {
                exact: true,
                path: '/app/management/pricing/list',
                component: lazy(() =>
                    import('src/views/management/GroupListView'))
            },
            {
                exact: true,
                path: '/app/management/pricing/create',
                component: lazy(() =>
                    import('src/views/management/GroupCreateView'))
            },
            {
                exact: true,
                path: '/app/management/pricing/:groupId/edit',
                component: lazy(() =>
                    import('src/views/management/GroupEditView'))
            },
            {
                exact: true,
                path: '/app/management/pricing/country',
                component: lazy(() =>
                    import('src/views/management/GroupCountryView'))
            },
            {
                exact: true,
                path: '/app/management/pricing/jurisdictions',
                component: lazy(() =>
                    import('src/views/management/JurisdictionFeeView'))
            },
            {
                exact: true,
                path: '/app/management/currency',
                component: lazy(() =>
                    import('src/views/management/CurrencyListView'))
            },
            {
                exact: true,
                path: '/app/management/currency/list',
                component: lazy(() =>
                    import('src/views/management/CurrencyListView'))
            },
            {
                exact: true,
                path: '/app/management/currency/create',
                component: lazy(() =>
                    import('src/views/management/CurrencyCreateView'))
            },
            {
                exact: true,
                path: '/app/management/currency/:currencyId/edit',
                component: lazy(() =>
                    import('src/views/management/CurrencyEditView'))
            },
            {
                exact: true,
                path: '/app/applications',
                component: () => < Redirect to="/app/applications/orders/create" />
            },
            {
                exact: true,
                path: '/app/applications/orders/list/:cid',
                component: lazy(() =>
                    import('src/views/applications/OrderListView'))
            },
            {
                exact: true,
                path: '/app/applications/orders',
                component: lazy(() =>
                    import('src/views/applications/OrderListView'))
            },
            {
                exact: true,
                path: '/app/applications/orders/list',
                component: () => < Redirect to="/app/applications/orders" />
            },
            {
                exact: true,
                path: '/app/applications/orders/create',
                component: lazy(() =>
                    import('src/views/applications/OrderCreateView'))
            },
            {
                exact: true,
                path: '/app/applications/orders/create/:cid',
                component: lazy(() =>
                    import('src/views/applications/OrderCreateView'))
            },
            {
                exact: true,
                path: '/app/applications/orders/:orderId',
                component: lazy(() =>
                    import('src/views/applications/OrderDetailsView'))
            },
            {
                exact: true,
                path: '/app/applications/orders/history/list',
                component: lazy(() =>
                    import('src/views/applications/OrderHistoryView'))
            },
            {
                exact: true,
                path: '/app/applications/orders/search/list',
                component: lazy(() =>
                    import('src/views/applications/SearchView'))
            },
            {
                exact: true,
                path: '/app/applications/orders/report/history',
                component: lazy(() =>
                    import('src/views/applications/ReportHistoryView'))
            },
            // pre employment screening 
            {
                exact: true,
                path: '/app/applications/preorders',
                component: lazy(() =>
                    import('src/views/applications/PreEmployment/OrderListView'))
            },
            {
                exact: true,
                path: '/app/applications/preorders/create',
                component: lazy(() =>
                    import('src/views/applications/PreEmployment/OrderCreateView'))
            },
            {
                exact: true,
                path: '/app/applications/preorders/edit/:preOrderId',
                component: lazy(() =>
                    import('src/views/applications/PreEmployment/OrderEditView'))
            },
            {
                exact: true,
                path: '/app/applications/preorders/:preOrderId/:from?',
                component: lazy(() =>
                    import('src/views/applications/PreEmployment/OrderDetailsView'))
            },
            {
                exact: true,
                path: '/app/preorders/:preOrderId/candidates/create',
                component: lazy(() => import('src/views/applications/PreEmployment/CreateCandidate'))
            },
            {
                exact: true,
                path: '/app/preorders/:preOrderId/candidates/:candidateId',
                component: lazy(() =>
                    import('src/views/applications/PreEmployment/CandidateView'))
            },
            {
                exact: true,
                path: '/app/applications/preorders/history/list',
                component: lazy(() =>
                    import('src/views/applications/PreEmployment/OrderHistoryView'))
            },
            {
                exact: true,
                path: '/app/applications/preorders/candform/list',
                component: lazy(() =>
                    import('src/views/applications/PreEmployment/CandidateSampleForm'))
            },
            // ep assure orders 
            {
                exact: true,
                path: '/app/applications/eporders',
                component: lazy(() =>
                    import('src/views/applications/EpOrders/OrderListView'))
            },
            {
                exact: true,
                path: '/app/applications/eporders/edit/:epOrderId',
                component: lazy(() =>
                    import('src/views/applications/EpOrders/OrderEditView'))
            },
            {
                exact: true,
                path: '/app/applications/eporders/:filter?/:from?',
                component: lazy(() =>
                    import('src/views/applications/EpOrders/OrderListView'))
            },
            {
                exact: true,
                path: '/app/applications/eporderdetail/:epOrderId/:filter?/:from?',
                component: lazy(() =>
                    import('src/views/applications/EpOrders/OrderDetailsView'))
            },
            //
            {
                exact: true,
                path: '/app/applications/order-confirm',
                component: lazy(() =>
                    import('src/views/applications/DraftOrderConfirm'))
            },
            {
                exact: true,
                path: '/app/applications/handshakes/:order',
                component: lazy(() =>
                    import('src/views/applications/HandShakeView'))
            },
            {
                exact: true,
                path: '/app/statistics',
                component: () => < Redirect to="/app/statistics/activity/company/summary" />
            },
            {
                exact: true,
                path: '/app/statistics/activity/company/summary',
                component: lazy(() =>
                    import('src/views/statistics/CompanySummaryView'))
            },
            {
                exact: true,
                path: '/app/statistics/activity/user/summary',
                component: lazy(() =>
                    import('src/views/statistics/UserSummaryView'))
            },
            {
                exact: true,
                path: '/app/statistics/activity/api/summary',
                component: lazy(() =>
                    import('src/views/statistics/APISummaryView'))
            },
            {
                exact: true,
                path: '/app/management',
                component: () => < Redirect to="/app/management/customers" />
            },
            {
                component: () => < Redirect to="/404" />
            }
        ]
    },
    {
        path: '*',
        layout: MainLayout,
        routes: [
            {
                exact: true,
                path: '/home',
                component: HomeView
            },
            {
                component: () => < Redirect to="/404" />
            }
        ]
    }
];

const renderRoutes = (routes) => (routes ?
    (<Suspense fallback={< LoadingScreen />} >
        <Switch> {
            routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={
                            (props) => (
                                <Guard>
                                    <Layout > {
                                        route.routes ?
                                            renderRoutes(route.routes) :
                                            < Component {...props} />}
                                    </Layout>
                                </Guard>
                            )
                        }
                    />
                );
            })
        }
        </Switch>
    </Suspense>
    ) : null);

function Routes() {
    return renderRoutes(routesConfig);
}

export default Routes;