/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_PRODUCTS,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  SET_ALLOWED_PRODUCTS,
  TOGGLE_CUSTOM_MODAL,
} from 'src/actions/productActions';

const initialState = {
  products: [],
  product: null,
  allowedProducts: JSON.parse(localStorage.getItem('allowedProducts')) ?? [],
  isCustomModalVisible: false,
  purchaseService: { price: 0, currency: 'SGD' },
};

const productReducer = (state = initialState, action) => {
  switch (action.type) { 

    case GET_PRODUCTS: {
      const products = action.payload
      return produce(state, (draft) => {
        draft.products = products
      })
    }

    case CREATE_PRODUCT: {
      const product = action.payload;

      return produce(state, (draft) => {
        draft.product = product;
      });
    }

    case UPDATE_PRODUCT: {
        const product = action.payload;
        return produce(state, (draft) => {
          draft.product = product;
        });
    }

    case SET_ALLOWED_PRODUCTS: {
      return produce(state, (draft) => {
        draft.allowedProducts = action.payload;
      });
    }

    case TOGGLE_CUSTOM_MODAL: {
      return produce(state, (draft) => {
        draft.isCustomModalVisible = action.payload.visibility;
        draft.purchaseService = action.payload.service;
      });
    }

    default: {
      return state;
    }
  }
};

export default productReducer;
