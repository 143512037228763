import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IconButton,
  SvgIcon,
  Tooltip,
  makeStyles,
  FormControl as MuiFormControl,
} from "@material-ui/core";
// import { Settings as SettingsIcon } from 'react-feather';
import CreditCard from '@material-ui/icons/AccountBalanceWallet';
import useSettings from 'src/hooks/useSettings';
// import { THEMES } from 'src/constants';
import StripeCheckout from 'react-stripe-checkout'

import styled from "styled-components";
import { spacing } from "@material-ui/system";
import Rodal from 'rodal';
import 'src/components/rodal.css'
import { setPayment } from 'src/actions/orderActions';
import { updateProfile } from 'src/actions/accountActions';
import { useSnackbar } from 'notistack';
// import { restoreSettings } from 'src/utils/settings';

const API_URL = process.env.REACT_APP_API_URL;

//tempcode stripePublishKey
// const { settings } = useSettings();
// const stripePublishKey = settings.stripePublishKey //'pk_test_pLMVnwyicTZhM89oNBhK33FV00UQxTU8bc';

const FormControl = styled(MuiFormControl)(spacing);

const useStyles = makeStyles((theme) => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  },
  popover: {
    width: 320,
    padding: theme.spacing(2)
  }
}));

function Settings() {
  // const classes = useStyles();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const { enqueueSnackbar } = useSnackbar();

  const { settings } = useSettings();
  const stripePublishKey = settings.stripePublishKey //'pk_test_pLMVnwyicTZhM89oNBhK33FV00UQxTU8bc';

  const [rodal_visible1, setRodal_visible1] = useState(false)
  const [message1, setMessage1] = useState('The available balance is required to complete orders. The balance for your account is managed by Exellar. Please contact enquiry@exellar.com should you have any queries.')

  const [sum, setSum] = useState('')
  const [balance, setBalance] = useState(0)
  const [panelLabel, setPanelLabel] = useState('Pay $0')

  const handlePayment = () => {
    setRodal_visible1(true)
  }

  const hideDialog_Cancel = () => {
    setRodal_visible1(false)
  }

  const hideDialog_Stripe = () => {
    setRodal_visible1(false)
  }

  const onToken = orderId => async (token) => {

    try {
      await dispatch(setPayment({ stripeToken: token.id, amount: sum, description: 'LINQ reload available balance by ' + account.user.name }))
      await dispatch(updateProfile({ ...account.user, password: '', balance: balance }));
    } catch (error) {
      enqueueSnackbar('Payment failed', {
        variant: 'error'
      });
    }
  }

  const onChange = e => {
    setSum(e.target.value)
    setPanelLabel('Pay ' + new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(e.target.value))
    let newBalance = account.user.balance + Number(e.target.value)
    setBalance(newBalance)
  }

  return (
    <>
      <Tooltip title="Reload available balance">
        <IconButton
          color="inherit"
          onClick={handlePayment}
          ref={ref}
        >
          {/* <SvgIcon fontSize="default"> */}
          <SvgIcon fontSize="medium">
            <CreditCard />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Rodal visible={rodal_visible1} onClose={hideDialog_Cancel}>
        <div className="header" style={{color:'black', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'}}><b>Enter the reload amount</b></div>
        <br />
        <div style={{color:'black', textAlign: 'left', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'}}>{message1}</div>
        {/* <div className="body" style={{ textAlign: 'center' }}>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <FormControl fullWidth>
                <br />
                <label style={{ marginLeft: '10px', marginTop: '8px', color: 'black', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>Amount</label>
              </FormControl>
            </Grid>
            <Grid item md={7} style={{ marginLeft: '10px' }}>
              <FormControl fullWidth>
                <InputLabel htmlFor="amount" style={{ paddingLeft: '10px', color: '#555', fontFamily: 'Roboto, sans-serif' }}>Enter the amount to reload</InputLabel>
                <Input name="amount" style={{ border: '0px !important' }} placeholder="The minimum amount is 100" value={sum} onChange={onChange} />
              </FormControl>
            </Grid>

            <Grid item md={12} style={{ padding: '0px !important' }}>
              <FormControl fullWidth>
                <label style={{ textAlign: 'left', fontSize: '12px', marginLeft: '20px', color: '#5f5f5f', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>All orders are displayed and processed in Singapore Dollars</label>
              </FormControl>
            </Grid>
          </Grid>
        </div>
        <div className="rodal-stripe-btn" style={{ right: '100px', width: '200px' }} onClick={hideDialog_Stripe}>
          {sum >= 100 &&
            <StripeCheckout
              style={{ width: '400px !important' }}
              label="Reload amount"
              currency="SGD"
              panelLabel={panelLabel}
              description="All orders are processed in SGD"
              name='Input your Credit/Debit Card'
              token={onToken()}
              stripeKey={stripePublishKey}
              image="/paylogo.png"
            />
          }
        </div> */}
        <button className="rodal-cancelp-btn rodal-cancelpre-btn" onClick={hideDialog_Cancel}>Cancel</button>
      </Rodal>
    </>
  );
}

export default Settings;
