import React from 'react';
import { Helmet } from 'react-helmet'
import DocumentMeta from 'react-document-meta'
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Hero from './Hero';
import Features from './Features';
import Testimonials from './Testimonials';
import FAQS from './FAQS';
import Handshake from './Handshake';

const meta = {
  canonical: 'https://www.linq-integrity.com/'
}

const useStyles = makeStyles(() => ({
  root: {}
}));

function HomeView() {
  const classes = useStyles();

  return (
    <>
      <DocumentMeta {...meta} />
      <Helmet>
        <title>Business Intelligence Services  | Corporate Due Diligence Services | Business Background Check Online - LINQ Integrity</title>
        <meta name="description" content={'Visit us to get business intelligence solutions that are necessary for successful transactional and business decision making.'} />
      </Helmet>
      <Page
        className={classes.root}
        title="Business Intelligence Services  | Corporate Due Diligence Services | Business Background Check Online - LINQ Integrity"
      >
        <Hero />
        <Handshake />
        <Features />
        <Testimonials />
        <FAQS />
      </Page>
    </>
  );
}

export default HomeView;
