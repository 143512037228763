/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { 
  Box, 
  Divider,
  Drawer,
  Hidden, 
  List,
  ListSubheader, 
  makeStyles
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import {
  Power as PowerIcon,
  User as UserIcon,

} from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 94,
    height: 'calc(100% - 94px)',
    background: '#f2f2f2' 
  },
  avatar: {
    cursor: 'pointer',
    width: 164,
    height: 74
  }
}));

function NavBar({ openMobile, onMobileClose, }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector((state) => state.account);
  const dispatch = useDispatch();

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async () => {
    try {
      let preOrderId = localStorage.getItem("preorderid");
      localStorage.removeItem("preorderid");
      history.push(`/customer/preorder/${preOrderId}`);
    } catch (error) {
      console.log(error);
      // enqueueSnackbar('Unable to logout', {
      //   variant: 'error'
      // });
    }
  };

  let preOrderId = localStorage.getItem('preorderid');
const navConfigPreOrder = [
  {
    subheader: 'EMEPMI Orders',
    href: `/customer/preorder/${preOrderId}`,
    items: [
      {
        title: 'Candidates List',
        href: `/customer/preorder/${preOrderId}/candidates`,
        icon: UserIcon
      } 
    ]
  }, 
];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden> 
        <Box p={2}>
          {navConfigPreOrder.map((config) => (
            <List
              key={config.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                  style={{fontSize: '1.00rem', fontWeight:500}}
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          ))
          }
        </Box>
        <Divider />
        <Box p={2} onClick={handleLogout}> 
            <List
              key={'config.subheader'}
            >
              {renderNavItems({
                items:  [
                      {
                        title: 'Signout',
                        icon: PowerIcon,
                        href: '#'
                      }
                    ] , pathname: location.pathname
              })}
            </List>
          </Box> 
      </PerfectScrollbar>
    </Box>
  );

  return (
    <div>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </div>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
