import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export const CREATE_COMPANY = '@company/create-company';
export const UPDATE_COMPANY = '@company/update-company';
export const GET_COMPANIES = '@company/list-company';

export function getCompanies() {

    const request = axios.get(API_URL + '/api/companies')

    return (dispatch) => {
        request.then((response) => dispatch({
            type: GET_COMPANIES,
            payload: response.data
        }));
    };
}

export function createCompany(company) {

    const request = axios.post(API_URL + `/api/companies`, company);

    return (dispatch) => {
        request.then((response) => dispatch({
            type: CREATE_COMPANY,
            payload: response.data
        }));
    };
}

export function updateCompany(update) {
    const request = axios.put(API_URL + `/api/companies/${update._id}`, update);

    return (dispatch) => {
        request.then((response) => dispatch({
            type: UPDATE_COMPANY,
            payload: response.data
        }));
    };
} 

export function deleteCompany(id) {
    // const request = axios.put(API_URL + `/api/companies/${update._id}`, update);
    axios.delete(API_URL + `/api/companies/${id}`);

    // return (dispatch) => {
    //     request.then((response) => dispatch({
    //         type: UPDATE_COMPANY,
    //         payload: response.data
    //     }));
    // };
} 
