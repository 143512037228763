import axios from 'axios';
import authService from 'src/services/authService';

const API_URL = process.env.REACT_APP_API_URL;

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const OKTA_LOGIN = '@account/okta-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const REGISTER_SUCCESS = '@account/register-success';
export const REGISTER_FAIL = '@account/register-fail';
export const UPDATE_PROFILE = '@account/update-profile';
export const UPDATE_ACCOUNT = '@account/update-account';
export const CREATE_CUSTOMER = '@account/create-customer';
export const UPDATE_CUSTOMER = '@account/update-customer';
export const DELETE_CUSTOMER = '@account/delete-customer';
export const SELECT_CUSTOMER = '@account/select-customer';
export const COUNTRY = '@account/country';
export const USERS = '@account/users';
export const UPDATE_COUNTRY = '@account/update-country';
export const FORGOT_SUCCESS = '@account/forgot-success';
export const FORGOT_FAIL = '@account/forgot-fail';

export function login(email, password, remember) {
    return async(dispatch) => {
        try {
            dispatch({ type: LOGIN_REQUEST });
            const user = await authService.loginWithEmailAndPassword(email, password, remember);

            dispatch({
                type: LOGIN_SUCCESS,
                payload: user
            });
        } catch (error) {
            dispatch({ type: LOGIN_FAILURE, payload: error });
            return error;
        }
    };
}

export function loginWithRemember() {
    return async(dispatch) => {
        try {
            dispatch({ type: LOGIN_REQUEST });

            const user = await authService.loginInWithRemember();

            dispatch({
                type: LOGIN_SUCCESS,
                payload: user
            });
        } catch (error) {
            dispatch({ type: LOGIN_FAILURE });
            throw error;
        }
    };
}

export function setUserData(user) {
    return (dispatch) => dispatch({
        type: SILENT_LOGIN,
        payload: user
    });
}

export function setUserDataOkta() {
    return (dispatch) => dispatch({
        type: OKTA_LOGIN
    });
}

export function logout() {
    return async(dispatch) => {
        authService.logout();

        dispatch({
            type: LOGOUT
        });
    };
}

export function register(formData) {
    return async(dispatch) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            dispatch({ type: REGISTER_SUCCESS });

            const res = await axios.post(API_URL + '/api/auth/signup', formData, config);

            dispatch({
                type: LOGIN_SUCCESS,
                payload: {
                    res
                }
            });
        } catch (error) {
            dispatch({ type: REGISTER_FAIL });
            throw error;
        }
    };
}

// tempcode no need for now
export function getCountries() {
    const request = axios.get(API_URL + '/api/auth/countries')

    return (dispatch) => {
        request.then((response) => dispatch({
            type: COUNTRY,
            payload: response.data
        }));
    };
}

export function getUsers() {
    const request = axios.get(API_URL + '/api/users');

    return (dispatch) => {
        request.then((response) => dispatch({
            type: USERS,
            payload: response.data
        }));
    };
};

// create new customer
export function createCustomer(customer) {
    
    return async(dispatch) => {
        try {
            const user = await axios.post(API_URL + `/api/users`, customer);

            dispatch({
                type: CREATE_CUSTOMER,
                payload: user
            });
        } catch (error) {
            dispatch({ type: LOGIN_FAILURE });
            throw error;
        }
    };
}

// update customer
export function updateCustomer(customer) {
    const request = axios.put(API_URL + `/api/users/${customer._id}`, customer);
    return (dispatch) => {
        request.then((response) => dispatch({
            type: UPDATE_CUSTOMER,
            payload: response.data
        }));
    };
}

// select the customer to create the order by admin
export function selectCustomer(customer) {
    
    return async(dispatch) => {
        try {
            dispatch({
                type: SELECT_CUSTOMER,
                payload: customer,
            });
        } catch (error) {
            dispatch({ type: LOGIN_FAILURE });
            throw error;
        }
    };
}

// update settings/user profile
export function updateProfile(update, isAdminNotCustomer = false) {
    const request = axios.put(API_URL + `/api/users/${update._id}`, update);
    return (dispatch) => {
        request.then((response) => {
            if (!isAdminNotCustomer) {
                dispatch({
                    type: UPDATE_PROFILE,
                    payload: response.data
                });
            }
        });
    };
}

export function deleteCustomer(id) {
    axios.delete(API_URL + `/api/users/${id}`);
}

// update settings/account
export function updateAccount(update) {
    const request = axios.put(API_URL + `/api/users/${update._id}`, update);
    return (dispatch) => {
        request.then((response) => dispatch({
            type: UPDATE_ACCOUNT,
            payload: response.data
        }));
    };
}

export function updateCountries(countryList) {
    const request = axios.put(API_URL + `/api/groups/countries`, countryList);

    return (dispatch) => {
        request.then((response) => dispatch({
            type: UPDATE_COUNTRY,
            payload: response.data
        }));
    };
};

export function forgot(formData) {
    return async(dispatch) => {
        try {
            const res = await axios.post(API_URL + '/api/forgot', formData);
            dispatch({
                type: FORGOT_SUCCESS,
                payload: res
            });
        } catch (error) {
            dispatch({ type: FORGOT_FAIL, payload: error.response.data.msg });
            throw error;
        }
    };
};

// reset password
export function resetPassword(formData) {
    return async(dispatch) => {
        try {
            const res = await axios.put(API_URL + '/api/forgot/resetPassword', formData);
            dispatch({
                type: FORGOT_SUCCESS,
                payload: res
            });
        } catch (error) {
            dispatch({ type: FORGOT_FAIL, payload: error.response.data.msg });
            throw error;
        }
    };

};

// determine expired password
export function expiredPassword(formData) {
    return async(dispatch) => {
        try {
            const res = await axios.put(API_URL + '/api/forgot/expiredPassword', formData);
            dispatch({
                type: FORGOT_SUCCESS,
                payload: res
            });
        } catch (error) {
            dispatch({ type: FORGOT_FAIL, payload: error.response.data.msg });
            throw error;
        }
    };
};