import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f2f2f2',
    paddingTop: 20,
    paddingBottom: 20
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  loveImage: {
    width: '40px',
    height: '100%'
  }
}));

function Features({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          component="p"
          variant="h1"
          color="secondary"
          align="center"
        >
          Comprehensive Solution
        </Typography>
        <Typography
          variant="h3"
          align="center"
          color="textPrimary"
          style={{ marginTop: '20px' }}
        >
          <span style={{color:'#17bac9'}}>LINQ</span> eKYC and due diligence addresses the following:
        </Typography>
        <Box mt={8}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <img
                  className={classes.loveImage}
                  alt="Business Intelligence Services  | Corporate Due Diligence Services | Business Background Check Online - LINQ Integrity"
                  src="/icons-04.png"
                />
                <Box ml={2}>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                  >
                    Integrity and compliance KYC checks and onboarding due diligence
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" mt={4}>
                <img
                  className={classes.loveImage}
                  alt="Business Intelligence Services  | Corporate Due Diligence Services | Business Background Check Online - LINQ Integrity"
                  src="/icons-06.png"
                />
                <Box ml={2}>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    gutterBottom
                  >
                    Confidential senior and C-suite executive profiling checks and due diligence
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <img
                  className={classes.loveImage}
                  alt="Business Intelligence Services  | Corporate Due Diligence Services | Business Background Check Online - LINQ Integrity"
                  src="/icons-05.png"
                />
                <Box ml={2}>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                  >
                    Standard pre-employment screening for general hires
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" mt={4}>
                <img
                  className={classes.loveImage}
                  alt="Business Intelligence Services  | Corporate Due Diligence Services | Business Background Check Online - LINQ Integrity"
                  src="/icons-09.png"
                />
                <Box ml={2}>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    gutterBottom
                  >
                    Director and key executive appointment checks and due diligence
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <img
                  className={classes.loveImage}
                  alt="Business Intelligence Services  | Corporate Due Diligence Services | Business Background Check Online - LINQ Integrity"
                  src="/icons-12.png"
                />
                <Box ml={2}>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    gutterBottom
                  >
                    Confidential basic and enhanced due diligence on business partners and personnel (including business partners, key customers and suppliers, vendors and contractors, investment targets and competitors etc.)
                  </Typography>
                </Box>
              </Box>
            </Grid>

          </Grid>
        </Box>
      </Container>
    </div>
  );
}

Features.propTypes = {
  className: PropTypes.string
};

export default Features;
