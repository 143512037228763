import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import StripeCheckout from 'react-stripe-checkout'
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { createEpOrder, setEpPayment, sendInvoice } from 'src/actions/epOrderActions';
import moment from 'moment';
import EpSuccess from './EpSuccess';
import Rodal from 'rodal';
import EpOrderModal from '../views/applications/EpOrders/EpOrderModal';
import 'src/components/rodal.css';
import EpPaymentSelectModal from './EpPaymentSelectModal';

const WIDTH = 600;
const HEIGHT = 620;
const SERVICE_PRICE = 95;
const CURRENCY = 'SGD';

const useStyles = makeStyles(() => ({
  modalContainer: {
    zIndex: '1200',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '2px',
    paddingRight: '21px',
  },
  subTitle: {
    fontSize: '15px',
    fontWeight: 'bold',
    paddingRight: '21px',
  },
  body: {
    overflow: 'auto',
    height: 'calc(100% - 108px)',
    borderBottom: '1px solid #e9e9e9',
  },
  container2: {
    margin: 0,
    width: '100%',
  },
  footer: {
    height: '50px',
    
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    position: 'absolute',
    bottom: '17px',
    right: '17px',
  },
  btnNext: {
    marginLeft: '10px',
  },
  btnOK: {
    width: '110px',
  },
  loader: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255, 255, 255, 0.7)',
    zIndex: '1',
  },
}));

const initialValues = {
  companyName: '',
  email: '',
  fullName: '',
  phone: '',
  candidateName: '',
  candidateEmail: '',
  candidatePhone: '',
  candidateCnt: 1,
};

const validationSchema = yup.object({
  companyName: yup.string().nullable(),
  email: yup.string().email('Email must be a valid email').required('Email is a required field'),
  fullName: yup.string().required('Full Name is a required field'),
  phone: yup.string().required('Phone number is a required field'),
  candidateName: yup.string().required('Candidate Name is a required field'),
  candidateEmail: yup.string().email('Email must be a valid email').required('Candidate Email is a required field'),
  candidatePhone: yup.string().nullable(),
  candidateCnt: yup.number().required('No. of Qualifications to verify is a required field').integer('No. of Qualifications to verify should be integer').positive('No. of Qualifications to verify should be positive number'),
});

const formFields = [
  {
    helperText: '',
    isRequired: false,
    label: 'Company name',
    name: 'companyName',
    type: 'text',
  },
  {
    helperText: '',
    isRequired: true,
    label: 'Your email address',
    name: 'email',
    type: 'email',
  },
  {
    helperText: '',
    isRequired: true,
    label: 'Your full name',
    name: 'fullName',
    type: 'text',
  },
  {
    helperText: '',
    isRequired: true,
    label: 'Your phone number',
    name: 'phone',
    type: 'text',
  },
  {
    helperText: '',
    isRequired: true,
    label: 'Candidate name',
    name: 'candidateName',
    type: 'text',
  },
  {
    helperText: '',
    isRequired: true,
    label: 'Candidate email address',
    name: 'candidateEmail',
    type: 'email',
  },
  {
    helperText: '',
    isRequired: false,
    label: 'Candidate phone number',
    name: 'candidatePhone',
    type: 'text',
  },
  {
    helperText: '',
    isRequired: true,
    label: 'No. of Qualifications to verify',
    name: 'candidateCnt',
    type: 'number',
  },

];

const getOrderNumber = () => {
  return moment(new Date()).format("YYMMDD-HHmmss");
}

function PurchaseModal({ visible, toggle, confirm, width=WIDTH, height=HEIGHT }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [cancelRodalVisible, setCancelRodalVisible] = useState(false);
  const [invoiceModalVisible, setInvoiceModalVisible] = useState(false);
  const [payModalVisible, setPayModalVisible] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentEpOrder, stripePublishKey } = useSelector((state) => state.epOrder);
  const { enqueueSnackbar } = useSnackbar();
  const { errors, handleBlur, handleChange, handleSubmit, handleReset, isSubmitting, touched, values } = useFormik({
    validateOnBlur: false,
    validationSchema,
    initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setIsLoading(true);
      // call API
      await dispatch(createEpOrder({ ...values, price: SERVICE_PRICE, orderNo: getOrderNumber() }));
      setSubmitting(false);
      setIsLoading(false);
      setCurrentStep(2);
    }
  });

  const handleClickCancel = () => {
    if (currentStep === 2) {
      setCancelRodalVisible(true);
    } else {
      handleReset();
      setCurrentStep(1);
      toggle();
    }
  }

  const confirmCancelRodal = () => {
    handleReset();
    setCurrentStep(1);
    toggle();
    setCancelRodalVisible(false);
  }

  const toggleInvoiceModal = () => {
    setInvoiceModalVisible(!invoiceModalVisible);
  };
  const confirmInvoiceModal = async () => {
    //send invoice
    await dispatch(sendInvoice(currentEpOrder?._id, costValue));
    toggleInvoiceModal();
    togglePayModal();
    setCurrentStep(3);
    // confirmCancelRodal();
  };

  const togglePayModal = () => {
    setPayModalVisible(!payModalVisible);
  };

  const { panelLabel, unitCost, cost, costValue } = useMemo(() => {
    const costValue = parseFloat(values.candidateCnt * SERVICE_PRICE);
    const unitCost = new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(SERVICE_PRICE);
    const cost = new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(costValue);
    const panelLabel = ('Pay ' + new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: CURRENCY,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(costValue));
    return {panelLabel, unitCost, cost, costValue};
  }, [values]);

  const onToken = () => async (token) => {
    setIsLoading(true);
    togglePayModal();
    console.log(token);
    // return;
    try {
      await dispatch(setEpPayment({
        stripeToken: token.id,
        amount: cost,
        description: 'description',
        epOrderId: currentEpOrder._id
      }));
      enqueueSnackbar('Payment is successful!', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setIsLoading(false);
      setCurrentStep(3);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      enqueueSnackbar('Payment has failed to be processed.', {
        variant: 'error'
      });
    }
  }

  const StripeButton = () => {
    return (
      <StripeCheckout
        style={{ width: '400px !important' }}
        label="Make Payment"
        currency={CURRENCY}
        panelLabel={panelLabel}
        description={`All orders are processed in ${CURRENCY}`}
        name='Input your Credit/Debit Card'
        token={onToken()}
        stripeKey={stripePublishKey}
        image="/paylogo.png"
      >
        <Button className={classes.btnNext} variant="contained" color="secondary">
          Make Payment
        </Button>
      </StripeCheckout>
    );
  };

  return (
    <Rodal
      width={width}
      height={height}
      visible={visible}
      onClose={toggle}
      customStyles={{zIndex: '1201'}}
      className={classes.modalContainer}
      closeMaskOnClick={false}
    >
      <div className="header">
        <div className={classes.title}>
          MOM EP-ASSURE ORDER
        </div>
        {currentStep === 1 && <div className={classes.subTitle}>
          Please fill in the form below and click Next
        </div>}
        {/* <div>
          {currentStep !== 3 && `${CURRENCY} ${SERVICE_PRICE}`}
        </div> */}
      </div>
      <div className={`body bodyFont ${classes.body}`}>
        {currentStep === 1 && 
          <form>
            {formFields.map((field, index) => {
              return <Box mb={1} key={index}>
                <FormControl fullWidth>
                  <TextField
                    error={Boolean(touched[field.name] && errors[field.name])}
                    fullWidth
                    helperText={field.helperText}
                    label={field.label}
                    margin="dense"
                    name={field.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required={field.isRequired}
                    type={field.type}
                    value={values[field.name]}
                    variant="outlined"
                  />
                </FormControl>
                {touched[field.name] && errors[field.name] && (
                  <Box mt={0}>
                    <FormHelperText error>
                      {errors[field.name]}
                    </FormHelperText>
                  </Box>
                )}
              </Box>
            })}
          </form>
        }
        {currentStep === 2 && (
          <Grid container spacing={2} className={classes.container2}>
            <Grid item xs={12} >
              <Typography varient="h1" className={classes.textBold}>
                The following information has been provided.  <br/>To confirm the order, please click on the <b>Make Payment</b> button below.
              </Typography>
            </Grid>
            <Grid item xs={12} >
              <Typography varient="h1" className={clsx(classes.textBold, classes.prices)}>
                Your Name: <b>{values.fullName}</b>
              </Typography>
            </Grid>
            <Grid item xs={12} >
              <Typography varient="h1" className={clsx(classes.textBold, classes.prices)}>
                Your Email: <b>{values.email}</b>
              </Typography>
            </Grid>
            <Grid item xs={12} >
              <Typography varient="h1" className={clsx(classes.textBold, classes.prices)}>
                Candidate Name: <b>{values.candidateName}</b>
              </Typography>
            </Grid>
            <Grid item xs={12} >
              <Typography varient="h1" className={clsx(classes.textBold, classes.prices)}>
                Candidate Email: <b>{values.candidateEmail}</b>
              </Typography>
            </Grid>
            <Grid item xs={12} >
              <Typography varient="h1" className={clsx(classes.textBold, classes.prices)}>
                Candidate Phone No: <b>{values.candidatePhone}</b>
              </Typography>
            </Grid>
            <Grid item xs={12} >
              <Typography varient="h1" className={clsx(classes.textBold, classes.prices)}>
                Service Price: <b>{CURRENCY} {unitCost}</b>
              </Typography>
            </Grid>
            <Grid item xs={12} >
              <Typography varient="h1" className={clsx(classes.textBold, classes.prices)}>
                No. of Qualifications to verify: <b>{values.candidateCnt}</b>
              </Typography>
            </Grid>
            <Grid item xs={12} >
              <Typography varient="h1" className={clsx(classes.textBold, classes.prices)}>
                Total Fee: <b>{CURRENCY} {unitCost} x {values.candidateCnt} = {CURRENCY} {cost}</b>
              </Typography>
            </Grid>
          </Grid>
        )}
        {currentStep === 3 && <EpSuccess orderNo={currentEpOrder?.orderNo} />}
      </div>
      <div className={classes.footer}>
        {currentStep !== 3 && (
          <>
            <Button variant="contained" color="secondary" onClick={handleClickCancel}>
              Cancel
            </Button>
            {currentStep === 1 && (
              <Button className={classes.btnNext} variant="contained" color="secondary" onClick={handleSubmit}>
                Next
              </Button>
            )}
            {currentStep === 2 && (
              <Button className={classes.btnNext} variant="contained" color="secondary" onClick={togglePayModal}>
                Make Payment
              </Button>
            )}
          </>
        )}
        {currentStep === 3 && (
          <Button className={classes.btnOK} variant="contained" color="secondary" onClick={handleClickCancel}>
            OK
          </Button>
        )}
        {/* <button className="rodal-confirm-btn" type="button" onClick={confirm}>OK</button>
        <button className="rodal-cancel-btn" type="button" onClick={toggle}>Cancel</button>*/}
      </div>
      {isLoading && (
        <div className={classes.loader}>
          <CircularProgress color="secondary" />
        </div>
      )}
      <EpOrderModal
        isVisible={cancelRodalVisible}
        toggle={() => setCancelRodalVisible(false)}
        title='Attention!'
        bodyText='Are you sure you wish to cancel?'
        confirm={confirmCancelRodal}
      />
      { stripePublishKey && <EpPaymentSelectModal
        isVisible={payModalVisible}
        toggle={togglePayModal}
        stripeKey={stripePublishKey}
        onToken={onToken}
        panelLabel={panelLabel}
        currency={CURRENCY}
        toggleInvoiceModal={toggleInvoiceModal}
        confirmInvoiceModal={confirmInvoiceModal}
      /> }
      <EpOrderModal
        isVisible={invoiceModalVisible}
        toggle={toggleInvoiceModal}
        title='Attention!'
        bodyText={`An invoice of ${CURRENCY} ${cost} will be sent to you. Click Ok to confirm the order.`}
        confirm={confirmInvoiceModal}
      />
    </Rodal>
  );
}

export default PurchaseModal;
