import jwtDecode from 'jwt-decode';
// import axios from 'src/utils/axios';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {

    const accessToken = this.getAccessToken();
    const oktaToken = this.getOktaToken();
    const remember = this.getRemember();

    // remember tempcode
    // if(!remember || remember === 'false') {
    //   this.setSession(null);
    // }

    if (!accessToken || !oktaToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  loginWithEmailAndPassword = (email, password, remember) => new Promise((resolve, reject) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    axios.post(API_URL + '/api/auth/signin', { email, password, remember }, config)
      .then((response) => {
        if (response.data.user) {
          this.setSession(response.data.accessToken);
          resolve(response.data);
        } else {
          reject('login error');
          // return('login error');
        }
      })
      .catch((error) => {
        console.log('auth catch error', error)
        reject(error);
      });
  })

  loginInWithToken = () => new Promise((resolve, reject) => {
    axios.get(API_URL + '/api/auth/me')
      .then((response) => {
        if (response.data.user) {
          resolve(response.data);
        } else {
          reject(response); 
          // return ('null');
        }
      })
      .catch((error) => {
        console.log(error)
        resolve([]);
        // reject(error);
        // reject(error);
      });
  })

  loginInWithRemember = () => new Promise((resolve, reject) => {
    axios.get(API_URL + '/api/auth/remember')
      .then((response) => {
        if (response.data.user) {
          resolve(response.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })

  logout = () => {
    this.setSession(null);
  }

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      axios.defaults.headers.common['x-auth-token'] = accessToken
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('oktaToken');
      localStorage.removeItem('settings');
      // localStorage.removeItem('entityName');
      // localStorage.removeItem('identification');
      // remember tempcode
      // localStorage.removeItem('remember');
      delete axios.defaults.headers.common['x-auth-token'];
      // delete axios.defaults.headers.common.Authorization;
    }
  }

  getAccessToken = () => localStorage.getItem('accessToken');
  getOktaToken = () => localStorage.getItem('oktaToken');
  getRemember = () => localStorage.getItem('remember');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    // if(decoded.exp > currentTime) {

    // }
    return true
    // return decoded.exp > currentTime;
  }

  isAuthenticated = () => !!this.getAccessToken()
}

const authService = new AuthService();

export default authService;
