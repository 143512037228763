import React, {
  useRef,
  useState,
  useEffect,
  useCallback
} from 'react';
// import { Link as RouterLink } from 'react-router-dom';
// import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
// import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core'; 

function Account({currencies}) { 
  const ref = useRef(null); 

  const account = useSelector((state) => state.account); 

  if (!currencies) {
    return null;
  } 

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        ref={ref}
      >
        <Hidden smDown>
          <Typography
            variant="h6"
            color="inherit"
          >
            <b>{`${account.user.name}`}</b>, Balance: {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: `${account.user.currency}`,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(account.user.balance)}
          {/* }).format(account.user.balance / currencies)} */}
          </Typography>
        </Hidden>
      </Box>
    </>
  );
}

export default Account;
