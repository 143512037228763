/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
    GET_COMPANIES,
    CREATE_COMPANY,
    UPDATE_COMPANY
} from 'src/actions/companyActions';

const initialState = {
    companies: [],
    company: null
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) { 

    case GET_COMPANIES: {
      const companies = action.payload
      return produce(state, (draft) => {
        draft.companies = companies
      })
    }

    case CREATE_COMPANY: {
      const company = action.payload;

      return produce(state, (draft) => {
        draft.company = company;
      });
    }

    case UPDATE_COMPANY: {
        const company = action.payload;
        return produce(state, (draft) => {
          draft.company = company;
        });
      }

    default: {
      return state;
    }
  }
};

export default companyReducer;
