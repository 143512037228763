import React, {useEffect, useCallback, useState, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Account from './Balance'; 
import Settings from './ReloadBalance';

import axios from 'axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
const API_URL = process.env.REACT_APP_API_URL; 

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: '#4e5961' 
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: '#4e5961' 
    } : {}
  },
  toolbar: {
    minHeight: 99
  }
}));

function TopBar({
  className,
  onMobileNavOpen,
  ...rest
}) {
  const classes = useStyles();

  const account = useSelector((state) => state.account);
  const isMountedRef = useIsMountedRef();
  const [currencies, setCurrencies] = useState(null);

  const getCurrencies = useCallback(() => {
    let currencyName = account.user.currency
    if (currencyName !== 'SGD') {
      axios
        .get(API_URL + '/api/currencies/' + currencyName)
        .then((response) => {
          // if (isMountedRef.current) {
            setCurrencies(response.data[0].rate)
          // }
        });
    } else {
      setCurrencies(1)
    }
  }, [isMountedRef]);

  useEffect(() => {
    getCurrencies();
  }, [getCurrencies]);

  if (!currencies) {
    return null;
  }

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/app">
            <Logo />
          </RouterLink>
        </Hidden>
        <Box
          ml={2}
          flexGrow={1}
        />
        <Box ml={2}>
          <Account currencies={currencies} />
        </Box>
        <Settings />
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
