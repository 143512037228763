import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

function CandidateGuard({ children }) {
  const account = useSelector((state) => state.account);

  if (account.user) {
    return <Redirect to="/app" />;
  }

  let preorderid = localStorage.getItem("preorderid");

  if(preorderid === null || preorderid === "") {
    console.log(`/customer/preorder/${preorderid}`);
    // return <Redirect to={`/customer/preorder/${preorderid}/login`} />;
    // return <Redirect to="/login" />;
  } 
  return children;
}

CandidateGuard.propTypes = {
  children: PropTypes.any
};

export default CandidateGuard;
