/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { 
  Box, 
  Divider,
  Drawer,
  Hidden, 
  List,
  ListSubheader, 
  makeStyles
} from '@material-ui/core';
import { logout } from 'src/actions/accountActions';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import {
  Briefcase as BriefcaseIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Power as PowerIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  PieChart as PieChartIcon,
  Users as UsersIcon,
  Settings as SettingIcon,
  Star as StarIcon,
  Search as SearchIcon,
} from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';

const navConfig = [
  {
    subheader: 'Dashboard',
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/app/reports/dashboard'
      },
    ]
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Companies',
        icon: FolderIcon,
        href: '/app/management/companies',
        items: [
          {
            title: 'List Companies',
            href: '/app/management/companies'
          }, 
          {
            title: 'Create Company',
            href: '/app/management/companies/new/create'
          }
        ]
      }, {
        title: 'Users',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'List Users',
            href: '/app/management/customers'
          }, 
          {
            title: 'Create User',
            href: '/app/management/customers/create'
          }
        ]
      },
      {
        title: 'Product Grouping',
        icon: LayoutIcon,
        href: '/app/management/categories',
        items: [
          {
            title: 'List Product Grouping',
            href: '/app/management/categories'
          },
          {
            title: 'Create Product Grouping',
            href: '/app/management/categories/create'
          }
        ]
      },{
        title: 'Products',
        icon: TrelloIcon,
        href: '/app/management/products',
        items: [
          {
            title: 'List Products',
            href: '/app/management/products'
          },
          {
            title: 'Create Product',
            href: '/app/management/products/create'
          }
        ]
      },
      {
        title: 'Fee',
        href: '/app/management/pricing',
        items: [
          {
            title: 'Manage Fee',
            href: '/app/management/pricing'
          },
          {
            title: 'List Group',
            href: '/app/management/pricing/list'
          },
          {
            title: 'Create Group',
            href: '/app/management/pricing/create'
          },
          {
            title: 'Country Group',
            href: '/app/management/pricing/country'
          },
          {
            title: 'Jurisdiction Discount',
            href: '/app/management/pricing/jurisdictions'
          }
        ],
        icon: DollarSignIcon
      },
      {
        title: 'FOREX Setup',
        icon: BarChartIcon,
        href: '/app/management/currency',
        items: [
          {
            title: 'List Currency',
            href: '/app/management/currency'
          },
          {
            title: 'Add Currency',
            href: '/app/management/currency/create'
          }
        ]
      }
    ]
  },
  {
    subheader: 'Applications',
    items: [ 
      {
        title: 'Orders',
        icon: EditIcon,
        href: '/app/applications/orders',
        items: [
          {
            title: 'Create Order',
            href: '/app/applications/orders/create'
          },{
            title: 'List Orders',
            href: '/app/applications/orders'
          },{
            title: 'Order History',
            href: '/app/applications/orders/history/list'
          },
          {
            title: 'Custom Search',
            href: '/app/applications/orders/search/list'
          }
        ]
      },
      {
        title: 'EMEPMI Orders',
        icon: EditIcon,
        href: '/app/applications/preorders',
        items: [
          {
            title: 'Create Order',
            href: '/app/applications/preorders/create'
          },
          {
            title: 'List Orders',
            href: '/app/applications/preorders'
          },
          {
            title: 'Order History',
            href: '/app/applications/preorders/history/list'
          },
          {
            title: 'Forms',
            href: '/app/applications/preorders/candform/list'
          }
        ]
      }   
    ]
  }, 
  {
    subheader: 'Reports',
    items: [ 
      {
        title: 'Activity Summary',
        icon: StarIcon,
        href: '/app/statistics/activity',
        items: [
          {
            title: 'Company Summary',
            href: '/app/statistics/activity/company/summary'
          },{
            title: 'User Summary',
            href: '/app/statistics/activity/user/summary'
          },
          {
            title: 'API Summary',
            href: '/app/statistics/activity/api/summary'
          }
        ]
      },
      {
        title: 'API History',
        icon: BriefcaseIcon,
        href: '/app/applications/orders/report/history' 
      },
    ]
  }, 
  {
    subheader: 'Settings',
    href: '/app/pages',
    items: [
      {
        title: 'User',
        href: '/app/profile',
        icon: UserIcon
      },
      {
        title: 'Account',
        href: '/app/account',
        icon: SettingIcon
      },
      {
        title: 'Search Parameters',
        href: '/app/searchparam',
        icon: SearchIcon
      }
    ]
  }, 
];


const navConfigCustomer = [
  {
    subheader: 'Dashboard',
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/app/reports/dashboard'
      }
    ]
  }, 
  {
    subheader: 'Applications',
    items: [ 
      {
        title: 'Orders',
        icon: EditIcon,
        href: '/app/applications/orders',
        items: [
          {
            title: 'Create Order',
            href: '/app/applications/orders/create'
          },{
            title: 'List Orders',
            href: '/app/applications/orders'
          },
          {
            title: 'Order History',
            href: '/app/applications/orders/history/list'
          }
        ]
      } 
    ]
  }, 
  {
    subheader: 'Settings',
    href: '/app/pages',
    items: [
      {
        title: 'User',
        href: '/app/profile',
        icon: UserIcon
      } 
    ]
  }, 
];

const navConfigPartner = [
  {
    subheader: 'Dashboard',
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/app/reports/dashboard'
      }
    ]
  }, 
  {
    subheader: 'Applications',
    items: [ 
      {
        title: 'Orders',
        icon: EditIcon,
        href: '/app/applications/orders',
        items: [
          {
            title: 'Create Order',
            href: '/app/applications/orders/create'
          },{
            title: 'List Orders',
            href: '/app/applications/orders'
          },
          {
            title: 'Order History',
            href: '/app/applications/orders/history/list'
          },
          {
            title: 'Custom Search',
            href: '/app/applications/orders/search/list'
          }
        ]
      },
      {
        title: 'EMEPMI Orders',
        icon: EditIcon,
        href: '/app/applications/preorders',
        items: [
          {
            title: 'Create Order',
            href: '/app/applications/preorders/create'
          },
          {
            title: 'List Orders',
            href: '/app/applications/preorders'
          },
          {
            title: 'Order History',
            href: '/app/applications/preorders/history/list'
          }
        ]
      }  
    ]
  }, 
  {
    subheader: 'Settings',
    href: '/app/pages',
    items: [
      {
        title: 'User',
        href: '/app/profile',
        icon: UserIcon
      } 
    ]
  }, 
];

const navConfigReviewer = [
  {
    subheader: 'Dashboard',
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/app/reports/dashboard'
      },
    ]
  },
  {
    subheader: 'Applications',
    items: [ 
      {
        title: 'EMEPMI Orders',
        icon: EditIcon,
        href: '/app/applications/preorders',
        items: [
          {
            title: 'List Orders',
            href: '/app/applications/preorders'
          }
        ]
      }  
    ]
  }, 
  {
    subheader: 'Settings',
    href: '/app/pages',
    items: [
      {
        title: 'User',
        href: '/app/profile',
        icon: UserIcon
      } 
    ]
  }, 
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 94,
    height: 'calc(100% - 94px)',
    background: '#f2f2f2' 
  },
  avatar: {
    cursor: 'pointer',
    width: 164,
    height: 74
  }
}));

function NavBar({ openMobile, onMobileClose, }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector((state) => state.account);
  const dispatch = useDispatch();

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      history.push('/');
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden> 
        <Box p={2}>
          {user.role === 'admin' ?
          navConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                  style={{fontSize: '1.00rem', fontWeight:500}}
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          ))
          :
          user.role === 'partner' ?
          navConfigPartner.map((config) => (
            <List
              key={config.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                  style={{fontSize: '1.00rem', fontWeight:500}}
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          )) 
          :
          user.role === 'customer' ?
          navConfigCustomer.map((config) => (
            <List
              key={config.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                  style={{fontSize: '1.00rem', fontWeight:500}}
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          ))
          :
          navConfigReviewer.map((config) => (
            <List
              key={config.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                  style={{fontSize: '1.00rem', fontWeight:500}}
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          ))
          }
        </Box>
        <Divider />
        <Box p={2} onClick={handleLogout}> 
            <List
              key={'config.subheader'}
            >
              {renderNavItems({
                items:  [
                      {
                        title: 'Signout',
                        icon: PowerIcon,
                        href: '#'
                      }
                    ] , pathname: location.pathname
              })}
            </List>
          </Box> 
      </PerfectScrollbar>
    </Box>
  );

  return (
    <div>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </div>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
