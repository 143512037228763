import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 100,
    paddingBottom: 20,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  image: {
    textAlign: 'right',
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '75%',
      height: 'auto',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    },
    '&:hover': {
      // transform: 'rotate(5deg) scale(1.3)'
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  },
  textJusfity: {
    textAlign: 'justify'
  }
}));

function Hero({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <h2
                style={{ fontSize: '18px', textAlign: 'left', color: '#f50057', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}
                color="secondary"
              >
                One Single Platform | Total Business Intelligence
              </h2>
              <h1
                color="textPrimary"
                style={{ fontSize: '40px', fontFamily: 'Arial' }}
              >
                <span style={{ color: '#17bac9' }}><strong>LINQ</strong></span> eKYC and Due Diligence
              </h1>
              <Box mt={3}>
                <Typography
                  variant="h3"
                  color="textPrimary"
                  style={{
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', lineHeight: '1.6', fontWeight: 400, fontSize: '20px', textAlign: 'justify'
                  }}
                >
                  LINQ is dashboard driven intelligent "all-in-one" platform for all your KYC, on-boarding screening, business partner and key personnel due diligence and compliance assessment needs.
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
          >
            <Box position="relative">

              <div className={classes.image}>
                <img
                  alt="Business Intelligence Services  | Corporate Due Diligence Services | Business Background Check Online - LINQ Integrity"
                  src="/b.jpg"
                />
              </div>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            style={{ paddingTop: 0 }}
          >
            <Box mt={3}>
              <Typography
                variant="body1"
                className={classes.textJusfity}
              >
                Designed to meet the demands of a rapidly evolving business and regulatory landscape, LINQ empowers businesses and users in regulated industries to stay at the forefront of decision-making and deal execution. It comprehensively addresses the full spectrum of KYC processes, onboarding screening, due diligence on business partners and key personnel, and fulfills the needs of compliance risk management.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
