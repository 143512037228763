import React from "react";
import Rodal from 'rodal';
import 'src/components/rodal.css';

export default function EpOrderModal({ isVisible, title, bodyText, toggle, confirm }) {
  return (
    <Rodal visible={isVisible} onClose={toggle} customStyles={{zIndex: "110"}}>
      <div className="header">{title}</div>
      <br />
      <div className="body bodyFont">{bodyText}</div>
      <button className="rodal-confirm-btn" type="button" onClick={confirm}>OK</button>
      <button className="rodal-cancel-btn" type="button" onClick={toggle}>Cancel</button>
  </Rodal>
  );
}