import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Button,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#0c3353',
    paddingBottom: theme.spacing(12),
    color: 'white',
    '& dt': {
      marginTop: theme.spacing(2)
    }
  }
}));

function FAQS({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3} component="dl" >
          <Grid item xs={12} md={3} >
            <Box mt={6}>
              <dd>
                <Typography variant="h5" style={{ color: 'white' }} >
                  Who we are
                </Typography>
              </dd>
              <a href="https://exellar.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                <img src="/footer.png" style={{ marginTop: '10px', width: '140px' }} alt=''/>
                <p style={{ color: 'white', fontSize: '10px', marginTop: '0px' }}>Part of EXELLAR Group of Companies</p>
              </a>
              <dt>
                <Typography variant="body1" style={{ color: 'white', textAlign: 'justify' }} >
                  LinQ Integrity is driven by a highly-respected leadership team with decades of industry experience in Governance, Risk & Compliance and Regulatory Technology (RegTech), developed and delivered by EXELLAR.
                </Typography>
              </dt>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} align='center'>
            <Box mt={6}>
              <br />
              <dd>
                <Typography variant="h5" style={{ color: 'white' }} >
                  Transform your KYC, due diligence and business risk compliance today
                </Typography>
              </dd>
              <dt>
                <Typography variant="body1" style={{ color: 'white' }}  >
                  <Button
                    color="secondary"
                    component="a"
                    href="/register"
                    variant="contained"
                  >
                    Register Now
                  </Button>
                </Typography>
              </dt>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} align='left' style={{ paddingLeft: '50px' }}>
            <Box mt={6}>
              <dd>
                <Typography variant="h5" style={{ color: 'white' }} >
                  Headquarters
                </Typography>
              </dd>
              <dt>
                <Typography variant="body1" style={{ color: 'white' }} >
                  EXELLAR Consulting Pte. Ltd.
                </Typography>
                {/* <Typography variant="body1" style={{ color: 'white' }} >
                  LINQ Advisory Pte. Ltd.
                </Typography> */}
                <Typography variant="body1" style={{ color: 'white' }} >
                  1 Paya Lebar Link
                </Typography>
                <Typography variant="body1" style={{ color: 'white' }} >
                  #04-01 Office 567
                </Typography>
                <Typography variant="body1" style={{ color: 'white' }} >
                  Paya Lebar Quarter
                </Typography>
                <Typography variant="body1" style={{ color: 'white' }} >
                  Singapore 408533
                </Typography>
              </dt>
              <dt>
                <div style={{ display: 'flex' }}>
                  <PhoneIcon /> <p style={{ marginLeft: '10px', marginTop: '3px', color: 'white', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>+65 6955 8630</p>
                </div>
                <div style={{ display: 'flex' }}>
                  {/* <EmailIcon /> <p style={{ marginLeft: '10px', marginTop: '3px', color: 'white', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>forensic@exellar.com</p> */}
                  <EmailIcon /> <p style={{ marginLeft: '10px', marginTop: '3px', color: 'white', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>enquiry@exellarconsulting.com</p>
                </div>
                {/* <div style={{ display: 'flex' }}>
                  <EmailIcon /> <p style={{ marginLeft: '10px', marginTop: '3px', color: 'white', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>service@linq-integrity.com</p>
                </div> */}
              </dt>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} align='left' style={{ paddingLeft: '50px' }}>
            <Box mt={6}>
              <dd>
                <Typography variant="h5" style={{ color: 'white' }} >
                  EXELLAR Greater China
                </Typography>
                <Typography variant="h5" style={{ color: 'white' }} >
                  EXELLAR (Hong Kong) Limited
                </Typography>
              </dd>
              <dt>
                <Typography variant="body1" style={{ color: 'white' }} >
                  17/F Strand 50,
                </Typography>
                <Typography variant="body1" style={{ color: 'white' }} >
                  50 Bonham Strand,
                </Typography>
                <Typography variant="body1" style={{ color: 'white' }} >
                  Sheung Wan, Hong Kong
                </Typography>
              </dt>
            </Box>
            <Box mt={6}>
              <dd>
                <Typography variant="h5" style={{ color: 'white' }} >
                  EXELLAR (Malaysia) Sdn. Bhd.
                </Typography>
              </dd>
              <dt>
                <Typography variant="body1" style={{ color: 'white' }} >
                  Signature Q Sentral, 
                </Typography>
                <Typography variant="body1" style={{ color: 'white' }} >
                  Unit 37-2, Level 37, Q Sentral, 
                </Typography>
                <Typography variant="body1" style={{ color: 'white' }} >
                  No.2A, Jalan Stesen Sentral 2,
                </Typography>
                <Typography variant="body1" style={{ color: 'white' }} >
                  Kuala Lumpur Sentral,
                </Typography>
                <Typography variant="body1" style={{ color: 'white' }} >
                  Kuala Lumpur, 50470, Malaysia
                </Typography>
              </dt>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

FAQS.propTypes = {
  className: PropTypes.string
};

export default FAQS;
