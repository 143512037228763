import React from "react";
import { useSelector } from 'react-redux';
import {
  Avatar,
  Box,
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import Rodal from 'rodal';
import StripeCheckout from 'react-stripe-checkout'
import styled from "styled-components";

const MyAvatar = styled(Avatar)`
  margin-left: 10px;
  width: 100px !important;
  height: 100px !important;
`;
const CenteredContent = styled.div`
  text-align: center;
`;

const EpPaymentSelectModal = ({
  isVisible,
  toggle,
  stripeKey,
  onToken,
  panelLabel,
  currency,
  toggleInvoiceModal,
  confirmInvoiceModal
}) => {
  return (
    <Rodal visible={isVisible} onClose={toggle} customStyles={{ height: '260px' }}>
        <div className="header">Select Payment Method</div>
        <br />
        <div className="body bodyFont">
          < div >
            <CenteredContent>
              <Grid container spacing={2}>
                <Grid item md={1}></Grid>
                <Grid item md={4} style={{ backgroundColor: '#ededed', borderRadius: '6px', cursor: 'pointer' }}>
                  <StripeCheckout
                    style={{ hidden: 'true' }}
                    description={`All orders are processed in ${currency}`}
                    name='Input your Credit/Debit Card'
                    panelLabel={panelLabel}
                    token={onToken()}
                    stripeKey={stripeKey}
                    image="/paylogo.png"
                  >
                    <MyAvatar variant="square" alt="" src={'/bank.png'} />
                    <Typography variant="caption" display="block" gutterBottom>
                      Credit/ Debit card
                    </Typography>
                  </StripeCheckout>
                </Grid>
                <Grid item md={2}></Grid>
                <Grid item md={4} style={{ backgroundColor: '#ededed', borderRadius: '8px', cursor: 'pointer', textAlign: 'center' }} onClick={toggleInvoiceModal}>
                  <MyAvatar variant="square" alt="" src={'/wallet.png'} onClick={toggleInvoiceModal} />
                  <label htmlFor="raised-file" onClick={toggleInvoiceModal}>
                    <Typography variant="caption" display="block" gutterBottom>
                      Send Invoice
                    </Typography>
                  </label>
                </Grid>
                <Grid item md={1}></Grid>
              </Grid>

            </CenteredContent>
          </div>
        </div>
        <button className="rodal-cancelp-btn" onClick={toggle}>Cancel</button>
      </Rodal>
  );
}

export default EpPaymentSelectModal;