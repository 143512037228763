import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Grid,
  Box,
  Button,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 64,
    paddingBottom: 64
  },
  title: {
    marginTop: '20px',
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: 0
  },
  textJusfity: {
    textAlign: 'justify',
    marginTop: '20px'
  },
  loveImage: {
    height: '80px' 
  }
}));

function Testimonials({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <h2
          style={{ fontSize: '18px', textAlign: 'center', fontFamily:'Arial' }}
          color="textPrimary"
        >
          Why <span style={{color:'#17bac9'}}>LINQ</span> eKYC and Due Diligence
        </h2>
        <Typography
          variant="h1"
          color="secondary"
          align="center"
          // style={{color:'#17bac9'}}
        >
          What our Clients love about us
        </Typography>
        <Grid
          container
          spacing={10}
          style={{ marginTop: '10px' }}
        >
          <Grid item xs={12} md={4}>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
              <img
                className={classes.loveImage}
                alt="Business Intelligence Services  | Corporate Due Diligence Services | Business Background Check Online - LINQ Integrity"
                src="/icons-14.png"
              />
            </div>
            <Typography
              variant="h3"
              color="textPrimary"
              align="center"
            >
              Reliable & accurate information
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
              align="left"
              className={classes.textJusfity}
            >
              The information we provide is sourced from global providers trusted by International institutions,  supported by a network of localised intelligence.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
              <img
                className={classes.loveImage}
                alt="Business Intelligence Services  | Corporate Due Diligence Services | Business Background Check Online - LINQ Integrity"
                src="/icons-11.png"
              />
            </div>
            <Typography
              variant="h3"
              align="center"
            >
              Structured & actionable
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
              align="left"
              className={classes.textJusfity}
            >
              Our reports are prepared on a fit-for-purpose basis, supported by facts. This ensures the intelligence is structured, and more importantly, actionable.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
              <img
                className={classes.loveImage}
                alt="Business Intelligence Services  | Corporate Due Diligence Services | Business Background Check Online - LINQ Integrity"
                src="/icons-10.png"
              />
            </div>
            <Typography
              variant="h3"
              align="center"
            >
              Pay only for what you need
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
              align="left"
              className={classes.textJusfity}
            >
              LinQ is structured to allow a tiered approach that enables our clients to pay only for what they need, when they need it.
            </Typography>
          </Grid>
        </Grid>

        <Box
          mt={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            color="secondary"
            component="a"
            href="/register"
            variant="contained"
          >
            Register as user today or contact us for more information and demo
          </Button>
        </Box>
      </Container>
    </div>
  );
}

Testimonials.propTypes = {
  className: PropTypes.string
};

export default Testimonials;
