import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  Button
} from '@material-ui/core';
import { Menu as MenuIcon, LogOut as LogoutIcon, } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: '#4e5961' 
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: '#4e5961' 
    } : {}
  },
  toolbar: {
    minHeight: 99
  }
}));

function TopBar({
  className,
  onMobileNavOpen,
  ...rest
}) {
  const classes = useStyles();
  const history = useHistory();

  const account = useSelector((state) => state.account);

  const handleLogout = async () => {
    try {
      const candInfoId = localStorage.getItem('candInfoId');
      localStorage.removeItem('candInfoId');
      history.push(`/candidateform/${candInfoId}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/app">
            <Logo />
          </RouterLink>
        </Hidden>
        <Box
          ml={2}
          flexGrow={1}
        />
        <Box ml={2}>
          <Button color="secondary" onClick={handleLogout}>
            <div style={{display: 'flex', alignItems: 'center', color: 'white'}}>
              <span style={{paddingTop: '4px', marginRight: '5px'}}>Signout</span> <LogoutIcon size={20} /> 
            </div>
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
