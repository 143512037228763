import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export const CREATE_ORDER = '@order/create-order';
export const UPDATE_ORDER = '@order/update-order';
export const GET_ORDERS = '@order/list-order';
export const ORDER_FAILURE = '@account/order-failure';
export const SET_PAYMENT = '@account/set-payment';

export function getOrders() {

    const request = axios.get(API_URL + '/api/orders')

    return (dispatch) => {
        request.then((response) => dispatch({
            type: GET_ORDERS,
            payload: response.data
        }));
    };
}

export function createOrder(order) {

    return async(dispatch) => {

        try {
            let formData = new FormData();
            let subjectName = order.subjectName === '' ? ' ' : order.subjectName
            let referenceId = order.referenceId === '' ? ' ' : order.referenceId
            let bulkFileName = order.bulkFileName === '' ? ' ' : order.bulkFileName
            let singleFileName = order.singleFileName === '' ? ' ' : order.singleFileName
            let relevantInfo = order.relevantInfo === '' ? ' ' : order.relevantInfo

            let singleFileNameList = [];
            if(order.singleFileData) {
                order.singleFileData.forEach(item => {
                    formData.append("singlefiledata", item);
                    singleFileNameList.push(item.name);
                })
            }
            formData.append("singleFileNameList", JSON.stringify(singleFileNameList));

            let bulkFileNameList = [];
            if(order.bulkFileData) {
                order.bulkFileData.forEach(item => {
                    formData.append("bulkfiledata", item);
                    bulkFileNameList.push(item.name);
                })
            }
            formData.append("bulkFileNameList", JSON.stringify(bulkFileNameList));

            formData.append("userId", order.userId)
            formData.append("userName", order.userName)
            formData.append("orderNo", order.orderNo);
            formData.append("orderType", order.orderType);
            formData.append("fee", order.fee);
            formData.append("feeOption", order.feeOption);
            formData.append("iMapFeeOption", order.iMapFeeOption);
            formData.append("feeOptionLabel", order.feeOptionLabel);
            formData.append("iMapFeeOptionLabel", order.iMapFeeOptionLabel);
            formData.append("currencyFeeoptionLabel", order.currencyFeeoptionLabel);
            formData.append("iMapCurrencyFeeoptionLabel", order.iMapCurrencyFeeoptionLabel);
            formData.append("serviceId", order.serviceId);
            formData.append("type", order.type);
            formData.append("subjectName", subjectName);
            formData.append("referenceId", referenceId);
            formData.append("country", order.country);
            formData.append("countryItem", order.countryItem);
            formData.append("countryItemDisplay", order.countryItemDisplay);
            formData.append("jurisdiction", order.jurisdiction);
            formData.append("subjectNum", order.subjectNum);
            formData.append("orderStatus", order.orderStatus);
            formData.append("relevantInfo", relevantInfo);
            formData.append("instruction", order.instruction);
            formData.append("currencyFee", order.currencyFee);
            formData.append("currencyFeeOption", order.currencyFeeOption);
            formData.append("iMapCurrencyFeeOption", order.iMapCurrencyFeeOption);

            const res = await axios.post(API_URL + `/api/orders`, formData);

            dispatch({
                type: CREATE_ORDER,
                payload: res
            });
        } catch (error) {
            dispatch({ type: ORDER_FAILURE });
            throw error;
        }
    };
}

export function updateOrder(update) {
    const request = axios.put(API_URL + `/api/orders`, update);

    return (dispatch) => {
        request.then((response) => dispatch({
            type: UPDATE_ORDER,
            payload: response.data
        }));
    };
}

export function deleteOrder(id) {

    const request = axios.delete(API_URL + `/api/orders/${id}`);

    return (dispatch) => {
        request.then((response) => dispatch({
            type: GET_ORDERS,
            payload: response.data
        }));
    };
}

export function setPayment(payInfo) {
    return async(dispatch) => {

        try {
            const res = await axios.post(API_URL + '/api/orders/pay', payInfo);
            dispatch({
                type: SET_PAYMENT,
                payload: res.data
            });
        } catch (error) {
            dispatch({ type: ORDER_FAILURE });
            throw error;
        }
    };
};

export function uploadOrderFile(order, orderId) {
    return async(dispatch) => {
        try {
            const res = await axios.post(API_URL + `/api/orders/upload/${orderId}`, order);
            dispatch({
                type: SET_PAYMENT,
                payload: res.data
            });
        } catch (error) {
            dispatch({ type: ORDER_FAILURE });
            throw error;
        }
    };
    // try { 
    //     const res = await axios.post(APU_URL + `/api/orders/upload/${orderId}`, order, config);
    //     dispatch({ type: ORDER_SAVED, payload: res.data });
    //     getOrders()
    // } catch (err) {
    //     dispatch({ type: ORDER_ERROR, payload: err });
    // }
}