import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    paddingBottom: theme.spacing(2),
    color: 'black',
    marginLeft: '200px',
    marginTop: '30px',
    marginBottom: '20px'
  }
}));

function Footer({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <div style={{
          display: 'flex',
          justifyContent: 'center'
        }}>
          <Typography variant="body1" style={{ fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }} >
            All rights reserved. Copyright @ {new Date().getFullYear()} LinQ Advisory Pte. Ltd.
          </Typography>
        </div>
      </Container>
    </div>
  );
}

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
