import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import companyReducer from './companyReducer';
import notificationsReducer from './notificationsReducer';
import chatReducer from './chatReducer';
import mailReducer from './mailReducer';
import kanbanReducer from './kanbanReducer';
import productReducer from './productReducer';
import epOrderReducer from './epOrderReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  chat: chatReducer,
  company: companyReducer,
  epOrder: epOrderReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationsReducer,
  product: productReducer,
});

export default rootReducer;
